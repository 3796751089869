import React from "react";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getQueryParams, windowExists } from "@internal/utils-browser";

// This is only available in development and helps developers load studio if they just enter http://localhost:3000
export default function DevOnlyIndexPage() {
    if (windowExists()) {
        newRelicWrapper.addPageAction("studio-index-page");
        if (getQueryParams().raso) {
            return <div>You should be signed OUT and redirected to your original page</div>;
        }
        const queryParams = window.location.search || "?mpvId=standardBusinessCards";
        window.location.href = `${PATH_PREFIX}/studio/six/en-ie/${queryParams}`;
    }

    return <div></div>;
}

DevOnlyIndexPage.displayName = "DevOnlyIndexPage";
